



















































import Vue, { Ref, computed, ref } from "vue";
import HandbookMenu from "@/components/HandbookMenu.vue";
import ChapterEditor from "@/components/ChapterEditor.vue";
import ChapterPreview from "@/components/ChapterPreview.vue";
import { Chapter } from "@/types";
import api from "@/api";

export default Vue.extend({
  name: "Handbook",
  components: { HandbookMenu, ChapterEditor, ChapterPreview },
  setup() {
    const id: Ref<string | undefined> = ref(undefined);
    const heading: Ref<string | undefined> = ref(undefined);
    const content: Ref<string | undefined> = ref(undefined as string | undefined);
    const access: Ref<number | undefined> = ref(undefined as number | undefined);
    const chapter: Ref<Chapter | undefined> = ref(undefined as Chapter | undefined);
    const chapters: Ref<Chapter[] | undefined> = ref([] as Chapter[]);
    const loading = ref(false);
    const activeChapter = computed(() => {
      return chapter.value?.chapterId;
    });
    const hasPendingChanges = computed(() => {
      return (
        chapter &&
        (heading.value !== chapter.value?.title ||
          content.value !== chapter.value?.content ||
          access.value !== chapter.value?.access)
      );
    });
    const reset = (): void => {
      id.value = "";
      heading.value = undefined;
      content.value = undefined;
      chapter.value = undefined;
      access.value = undefined;
    };
    const setActiveChapter = async (section: Chapter): Promise<void> => {
      chapter.value = undefined;
      loading.value = true;
      id.value = section.chapterId;
      heading.value = section.title;
      access.value = section.access;
      chapter.value = await api.GetChapter(section.chapterId);
      content.value = chapter.value.content;
      loading.value = false;
    };
    const updateTableOfContent = async (): Promise<void> => {
      loading.value = true;
      const sections = await api.GetChaptersCompact();
      chapters.value = sections.sort((a, b) => a.displayOrder - b.displayOrder);
      loading.value = false;
    };
    const update = (section: Chapter): void => {
      updateTableOfContent();
      chapter.value = section;
    };
    updateTableOfContent();
    return {
      id,
      heading,
      content,
      access,
      chapter,
      chapters,
      loading,
      activeChapter,
      hasPendingChanges,
      reset,
      setActiveChapter,
      updateTableOfContent,
      update
    };
  }
});
