






























































































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import ImageUploadDialog from "@/components/ImageUploadDialog.vue";
import LinkDialog from "@/components/LinkDialog.vue";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
export default Vue.extend({
  name: "EditorMenu",
  components: { ImageUploadDialog, LinkDialog },
  props: {
    commands: {
      type: Object,
      default: undefined
    },
    isActive: {
      type: Object,
      default: undefined
    },
    getMarkAttrs: Function as PropType<(arg: string) => Record<string, string>>,
    editor: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    const { commands, isActive, getMarkAttrs, editor } = toRefs(props);
    const showImageDialog = ref(false);
    const showLinkDialog = ref(false);
    const link: Ref<string | undefined> = ref(undefined);
    const isColorActive = (color: string): boolean => {
      return getMarkAttrs.value("customtextcolor").color === color;
    };
    const openLinkDialog = (href: string): void => {
      link.value = href;
      showLinkDialog.value = true;
    };
    const handleLinkClick = (): void => {
      const { href } = getMarkAttrs.value("link");

      if (href) {
        return commands.value.link({ href: null });
      }

      const { selection, state } = editor.value;
      const { from, to } = selection;
      const text = state.doc.textBetween(from, to, " ");
      if (!text) {
        alertService.actions.add({
          text: "Please select the text you want to convert into a link",
          type: AlertType.INFO
        });
      } else {
        openLinkDialog(href);
      }
    };
    return {
      commands,
      isActive,
      link,
      showImageDialog,
      showLinkDialog,
      isColorActive,
      openLinkDialog,
      handleLinkClick
    };
  }
});
