




































import Vue, { Ref, ref, toRefs } from "vue";
import { toBase64Url } from "@/utils";
import { AlertType } from "@/services/alert/types";
import api from "@/api";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "ArticleImageInput",
  props: {
    image: String,
    label: {
      type: String,
      default: "Image"
    }
  },
  setup(props, context) {
    const { image, label } = toRefs(props);
    const loading = ref(false);
    const fileInput: Ref<HTMLFormElement | undefined> = ref(undefined);
    const clear = (): void => {
      context.emit("update:image", "");
    };
    const compressImage = async (image: string, name: string): Promise<void> => {
      loading.value = true;
      try {
        const { content } = await api.CompressImage({
          name: name || "unknown",
          content: image
        });
        context.emit("update:image", content);
      } catch (error) {
        (fileInput.value as HTMLFormElement).reset();
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed uploading image.";
        await alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    const onChange = async (file: File): Promise<void> => {
      if (!file) return;
      loading.value = true;
      const image = await toBase64Url(file);
      // Strip url part of the string
      const base64 = image.substring(image.indexOf(",") + 1);
      await compressImage(base64, file.name);
      loading.value = false;
    };
    return { image, label, fileInput, loading, clear, compressImage, onChange };
  }
});
