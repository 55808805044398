


































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import { Project } from "victoria-shared";
import ProjectActionButton from "@/components/ProjectActionButton.vue";
import ProjectStatusChip from "@/components/ProjectStatusChip.vue";
export default Vue.extend({
  name: "ProjectTable",
  components: { ProjectActionButton, ProjectStatusChip },
  props: {
    projects: Array as PropType<Project[]>,
    refreshProjects: Function as PropType<() => Promise<void>>
  },
  setup(props) {
    const { projects, refreshProjects } = toRefs(props);
    const loading = ref(false);
    const search: Ref<string | undefined> = ref(undefined);
    const sortBy = ref("commencement");
    const sortDesc = ref(true);
    const headers = [
      { text: "Name", value: "name" },
      { text: "Client", value: "client" },
      { text: "Commencement", value: "commencement" },
      { text: "Completion", value: "completion" },
      { text: "Status", value: "status", align: "center" },
      { text: "Actions", value: "actions", sortable: false, align: "right" }
    ];
    const refresh = async () => {
      loading.value = true;
      await refreshProjects.value();
      loading.value = false;
    };
    refresh();
    return {
      projects,
      refreshProjects,
      refresh,
      headers,
      search,
      loading,
      sortBy,
      sortDesc
    };
  }
});
