
















import TheAppBar from "@/components/TheAppBar.vue";
import Alerts from "@/components/Alerts.vue";
export default {
  name: "App",
  components: { TheAppBar, Alerts }
};
