

















import Vue, { Ref, ref } from "vue";
import api from "@/api";
import { Article } from "victoria-shared";
import { AlertType } from "@/services/alert/types";
import ArticleTable from "@/components/ArticleTable.vue";
import ArticleActionButton from "@/components/ArticleActionButton.vue";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "Articles",
  components: { ArticleTable, ArticleActionButton },
  setup() {
    const articles: Ref<Article[]> = ref([]);
    const refreshArticles = async (): Promise<void> => {
      try {
        articles.value = await api.GetArticles();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed fetching articles.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
    };
    return { articles, refreshArticles };
  }
});
