











































































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import InviteStatusChip from "@/components/InviteStatusChip.vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import { Invite } from "victoria-shared";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "InviteTable",
  components: { InviteStatusChip },
  props: {
    invites: Array as PropType<Invite[]>,
    refreshInvites: Function as PropType<() => Promise<void>>
  },
  setup(props) {
    const { invites, refreshInvites } = toRefs(props);
    const selected: Ref<Invite | undefined> = ref(undefined);
    const showConfirmation = ref(false);
    const loading = ref(false);
    const loadingItems: Ref<string[]> = ref([]);
    const search: Ref<string | undefined> = ref(undefined);
    const sortBy = ref("created");
    const sortDesc = ref(true);
    const headers = [
      {
        text: "Email",
        value: "email"
      },
      {
        text: "User type",
        value: "userType"
      },
      { text: "Status", value: "status" },
      { text: "Created", value: "created" },
      { text: "Expiry", value: "expiry" },
      { text: "Actions", value: "actions", sortable: false, align: "right" }
    ];
    const openConfirmation = (invite: Invite) => {
      selected.value = invite;
      showConfirmation.value = true;
    };
    const refresh = async () => {
      loading.value = true;
      await refreshInvites.value();
      loading.value = false;
    };
    const prettyDate = (dateString: string) => {
      const date = new Date(`${dateString}Z`);
      return date.toLocaleString();
    };
    const remove = async (id: string) => {
      loadingItems.value.push(id);
      try {
        await api.DeleteInvite(id);
        alertService.actions.add({
          text: "Removed invite.",
          type: AlertType.SUCCESS
        });
        await refresh();
        showConfirmation.value = false;
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed removing invite.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loadingItems.value = loadingItems.value.filter(item => item !== id);
    };
    const resend = async (id: string) => {
      loadingItems.value.push(id);
      try {
        await api.ResendInvite(id);
        alertService.actions.add({
          text: "Invite resent.",
          type: AlertType.SUCCESS
        });
        await refresh();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed resending invite.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loadingItems.value = loadingItems.value.filter(item => item !== id);
    };
    refresh();
    return {
      invites,
      selected,
      loading,
      loadingItems,
      search,
      sortBy,
      sortDesc,
      headers,
      openConfirmation,
      showConfirmation,
      refresh,
      prettyDate,
      remove,
      resend
    };
  }
});
