import { Project } from "victoria-shared";
import request from "../request";

const CONTROLLER = "projects";

export default {
  async GetProjects(): Promise<Array<Project>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetProject(id: string): Promise<Project> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateProject(project: Project): Promise<Project> {
    const req = await request.post(`${CONTROLLER}`, project);
    return req.data;
  },
  async UpdateProject(project: Project): Promise<Project> {
    const req = await request.put(`${CONTROLLER}/${project.id}`, project);
    return req.data;
  },
  async DeleteProject(id: string): Promise<void> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
