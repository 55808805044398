import authService from "@/services/auth";
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";

// Time to consider for refresh of token prior to token expiration
const TTL_MARGIN = 1000 * 60 * 5;

let refreshTokenPromise: Promise<void> | undefined = undefined;

export default async (instance: AxiosInstance) => {
  instance.interceptors.request.use(async config => {
    const { isAuthenticated, tokenExpiry } = authService;
    if (isAuthenticated) {
      if (!tokenExpiry || Date.now() + TTL_MARGIN >= tokenExpiry) {
        console.info("Refreshing token...");
        if (!refreshTokenPromise) {
          refreshTokenPromise = authService.actions.refreshToken();
        }
        await refreshTokenPromise;
        refreshTokenPromise = undefined;
      }
      const { token } = authService.state;
      if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    async (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const isAuthenticated = authService.isAuthenticated;
      if (isAuthenticated && error.response && [401, 403].includes(error.response.status)) {
        console.warn("Unauthorized, logging out user...");
        await authService.actions.logout();
      }
      if (error.response && error.response.status === 500) {
        error.response.data.message = "Something went wrong...";
      }
      throw error;
    }
  );
};
