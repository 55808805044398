import Vue from "vue";
import VueRouter from "vue-router";
import { routes, UNGUARDED_ROUTES } from "./routes";
import authService from "@/services/auth";

Vue.use(VueRouter);

const router = new VueRouter({ routes });

router.beforeEach((to, _from, next) => {
  const isAuthenticated = authService.isAuthenticated;
  const isAuthorized = isAuthenticated || (to.name && UNGUARDED_ROUTES.includes(to.name));
  isAuthorized ? next() : next({ name: "Login" });
});

export default router;
