import { User } from "victoria-shared";
import request from "../request";

const CONTROLLER = "accounts";

export default {
  async GetAccounts(): Promise<Array<User>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetAccount(id: string): Promise<User> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateAccount(): Promise<User> {
    const req = await request.post(`${CONTROLLER}`);
    return req.data;
  },
  async DeleteAccount(id: string): Promise<void> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async UpdateAccount(user: User): Promise<User> {
    const req = await request.put(`${CONTROLLER}/${user.id}`, {
      ...user
    });
    return req.data;
  }
};
