




















































import Vue, { computed, ref } from "vue";
import SignOutButton from "@/components/SignOutButton.vue";
import authService from "@/services/auth";
import alertService from "@/services/alert";

export default Vue.extend({
  name: "TheAppBar",
  components: { SignOutButton },
  setup() {
    const drawer = ref(false);
    const nav = ref([
      {
        icon: "mdi-account-supervisor",
        text: "Users",
        route: "/users"
      },
      {
        icon: "mdi-book-open",
        text: "Handbook",
        route: "/handbook"
      },
      {
        icon: "mdi-newspaper-variant-multiple",
        text: "News",
        route: "/news"
      },
      {
        icon: "mdi-folder-multiple",
        text: "Project",
        route: "/projects"
      },
      {
        icon: "mdi-account-group",
        text: "Crew Information",
        route: "/crew-information"
      }
    ]);
    const isAuthenticated = computed(() => {
      return authService.isAuthenticated;
    });
    const logout = () => {
      return alertService.actions.add({ text: "Logged out" });
    };
    return { drawer: drawer, nav: nav, isAuthenticated: isAuthenticated, logout: logout };
  }
});
