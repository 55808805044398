














































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import { Article } from "victoria-shared";
import ArticleActionButton from "@/components/ArticleActionButton.vue";
export default Vue.extend({
  name: "ArticleTable",
  components: { ArticleActionButton },
  props: {
    articles: Array as PropType<Article[]>,
    refreshArticles: Function as PropType<() => Promise<void>>
  },
  setup(props) {
    const { articles, refreshArticles } = toRefs(props);
    const loading = ref(false);
    const search: Ref<string | undefined> = ref(undefined);
    const sortBy = "created";
    const sortDesc = true;
    const headers = [
      { text: "Title", value: "title" },
      { text: "Created", value: "created" },
      { text: "Updated", value: "updated" },
      { text: "Header image", value: "image" },
      { text: "Actions", value: "actions", sortable: false, align: "right" }
    ];

    const refresh = async (): Promise<void> => {
      loading.value = true;
      await refreshArticles.value();
      loading.value = false;
    };
    const hasHeaderImage = ({ image }: Article): string => {
      return image ? "Yes" : "No";
    };
    const prettyDate = (dateString: string): string => {
      const date = new Date(`${dateString}Z`);
      return date.toLocaleString();
    };
    refresh();
    return {
      articles,
      refreshArticles,
      loading,
      search,
      sortBy,
      sortDesc,
      headers,
      refresh,
      hasHeaderImage,
      prettyDate
    };
  }
});
