export enum UserStatus {
  ACCESS = "Access",
  EXPIRED = "Expired",
  REQUESTED = "Requested"
}

export enum UserType {
  CLIENT = "Client",
  CREW = "Crew",
  PROJECT = "Project"
}

export enum ReorderDirection {
  UP = -1,
  DOWN = 1
}

export const UserTypeValue: Record<UserType, number> = {
  [UserType.CLIENT]: 1,
  [UserType.CREW]: 2,
  [UserType.PROJECT]: 4
};
