import Users from "../views/Users.vue";
import Handbook from "../views/Handbook.vue";
import Articles from "../views/Articles.vue";
import Projects from "../views/Projects.vue";
import Information from "../views/Information.vue";
import SignIn from "../views/SignIn.vue";
import { RouteConfig } from "vue-router";

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    redirect: "/users"
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  },
  {
    path: "/handbook",
    name: "Handbook",
    component: Handbook
  },
  {
    path: "/news",
    name: "Articles",
    component: Articles
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/crew-information",
    name: "Information",
    component: Information
  },
  {
    path: "/login",
    name: "Login",
    component: SignIn
  },
  { path: "/*", redirect: "/" }
];

export const UNGUARDED_ROUTES = ["Login"];
