























import Vue, { toRefs } from "vue";
export default Vue.extend({
  name: "ChapterPreview",
  props: {
    content: {
      type: String,
      default: ""
    },
    heading: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const { content, heading } = toRefs(props);
    return { content, heading };
  }
});
