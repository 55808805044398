






import Vue, { PropType, computed, toRefs } from "vue";
import { Invite } from "victoria-shared";
export default Vue.extend({
  name: "InviteStatusChip",
  props: {
    invite: Object as PropType<Invite>,
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { invite, small } = toRefs(props);
    const color = computed(() => {
      const invites = invite.value;
      return invites.isExpired ? "error" : "success";
    });
    const text = computed(() => {
      const invites = invite.value;
      return invites.isExpired ? "Expired" : "Active";
    });
    return { small, text, color };
  }
});
