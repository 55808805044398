import { RootState } from "@/store/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AttachmentState } from "./types";
import * as mutation from "./mutations";

function initialState(): AttachmentState {
  return {
    attachments: []
  };
}

const getters: GetterTree<AttachmentState, RootState> = {
  attachments: state => state.attachments
};

const actions: ActionTree<AttachmentState, RootState> = {
  async initialize({ dispatch }): Promise<void> {
    await dispatch("setAttachment");
  },
  async setAttachment({ commit }, attachment: string): Promise<void> {
    commit(mutation.SET_ATTACHMENTS, attachment);
  },
  async reset({ commit }) {
    commit(mutation.RESET);
  }
};

const mutations: MutationTree<AttachmentState> = {
  [mutation.SET_ATTACHMENTS](state, attachment: string) {
    state.attachments.push(attachment);
  },
  [mutation.RESET](state) {
    state.attachments = [];
  }
};

const module: Module<AttachmentState, RootState> = {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations
};

export default module;
