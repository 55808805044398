import { Image } from "tiptap-extensions";
interface PdfAttrs extends Node {
  attrs: {
    src: string;
    isPdf: boolean;
    fileName: string | undefined;
  };
}

export default class PdfNode extends Image {
  get schema() {
    return {
      attrs: {
        src: {
          default: null
        },
        isPdf: {
          default: false // Change default value to false
        },
        target: {
          default: null
        },
        fileName: {
          default: null
        }
      },
      inline: true, // Set inline to true for inline content
      group: "inline",
      content: "text*",
      draggable: true,
      parseDOM: [
        {
          tag: "img[src]",
          getAttrs: (dom: HTMLElement) => ({
            src: dom.getAttribute("src"),
            isPdf: false // Assuming all images are not PDFs
          })
        },
        {
          tag: 'a[href^="data:application/pdf;base64"]',
          getAttrs: (dom: HTMLElement) => ({
            src: dom.getAttribute("href")?.replace("data:application/pdf;base64,", ""),
            isPdf: true
          })
        }
      ],
      toDOM: (node: PdfAttrs) => {
        if (node.attrs.isPdf) {
          return [
            "a",
            {
              href: `${process.env.VUE_APP_API_BASE_URL}attachment/${node.attrs.src}`,
              target: "__blank"
            },
            node.attrs.fileName // Display the text for the link
          ];
        } else {
          return [
            "img",
            {
              src: node.attrs.src
            }
          ];
        }
      }
    };
  }
}
