import { Article } from "victoria-shared";
import request from "../request";

const CONTROLLER = "informationArticles";

export default {
  async GetInformationArticles(): Promise<Array<Article>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetInformationArticle(id: string): Promise<Article> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateInformationArticle(article: Article): Promise<Article> {
    const req = await request.post(`${CONTROLLER}`, article);
    return req.data;
  },
  async UpdateInformationArticle(article: Article): Promise<Article> {
    const req = await request.put(`${CONTROLLER}/${article.id}`, article);
    return req.data;
  },
  async DeleteInformationArticle(id: string): Promise<void> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
