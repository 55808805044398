




























import Vue, { ref, toRefs } from "vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "DeleteChapterButton",
  props: {
    id: String
  },
  setup(props, context) {
    const { id } = toRefs(props);
    const dialog = ref(false);
    const loading = ref(false);
    const close = (): void => {
      dialog.value = false;
    };
    const remove = async (): Promise<void> => {
      loading.value = true;
      try {
        await api.DeleteChapter(id.value);
        alertService.actions.add({
          text: "Removed chapter successfully.",
          type: AlertType.SUCCESS
        });
        context.emit("update");
        close();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed removing chapter.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    return { id, dialog, loading, close, remove };
  }
});
