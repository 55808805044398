import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import alertStore from "@/services/alert";
import authStore from "@/services/auth";
import store from "./store";
Vue.config.productionTip = false;

Vue.prototype.$alert = alertStore;
Vue.prototype.$auth = authStore;

const app = new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if ((window as any).Cypress) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).app = app;
}
