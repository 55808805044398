































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import { VuetifyForm } from "@/types";
import api from "@/api";
import { UserType } from "@/enums";
import { VALIDATION } from "@/utils";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "InviteUserButton",
  props: {
    refreshInvites: Function as PropType<() => Promise<void>>
  },
  setup(props) {
    const { refreshInvites } = toRefs(props);
    const form: Ref<VuetifyForm | undefined> = ref(undefined);
    const dialog = ref(false);
    const loading = ref(false);
    const userTypes = ref(Object.keys(UserType).map(k => UserType[k as keyof typeof UserType]));
    const email: Ref<string | undefined> = ref(undefined);
    const userType = ref(UserType.CREW);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.email()
    };
    const reset = (): void => {
      email.value = "";
      userType.value = UserType.CREW;
    };
    const close = (): void => {
      dialog.value = false;
      reset();
    };
    const getForm = (): VuetifyForm | undefined => {
      return form.value;
    };
    const validate = (): boolean | undefined => {
      const form = getForm();
      return form?.validate();
    };
    const submit = async (): Promise<void> => {
      if (validate()) {
        loading.value = true;
        try {
          email.value &&
            (await api.CreateInvite({ email: email.value?.trim(), userType: userType.value }));
          alertService.actions.add({
            text: "Created invite successfully.",
            type: AlertType.SUCCESS
          });
          await refreshInvites.value();
          close();
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message = errorResponse?.response?.data?.message || "Failed creating invite.";
          alertService.actions.add({ text: message, type: AlertType.ERROR });
        }
        loading.value = false;
      }
    };
    return { form, dialog, loading, userTypes, email, userType, rules, submit, close };
  }
});
