import { CompressImageRequest, CompressImageResponse } from "@/types";
import request from "../request";

const CONTROLLER = "images";

export default {
  async CompressImage(image: CompressImageRequest): Promise<CompressImageResponse> {
    const req = await request.post(`${CONTROLLER}`, { ...image });
    return req.data;
  }
};
