var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","rounded":"lg","loading":_vm.loading ? 'accent' : false}},[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('div',[_c('v-card-title',[_vm._v("Articles")]),_c('v-card-subtitle',[_vm._v("List of articles")])],1),_c('div',[_c('v-card-text',[_c('v-btn',{attrs:{"large":"","rounded":"","text":"","data-cy":"refresh-articles-button"},on:{"click":_vm.refresh}},[_vm._v(" Refresh "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1)],1)]),_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.articles,"items-per-page":10,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"data-cy":"articles-table"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.title))])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.prettyDate(item.created))+" ")])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.prettyDate(item.updated))+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.hasHeaderImage(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('article-action-button',{attrs:{"article":item,"refreshArticles":_vm.refresh},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","data-cy":"edit-article-button"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("View & edit")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","color":"primary","solo":"","hide-details":"","filled":"","flat":"","prepend-inner-icon":"mdi-magnify","label":"Search","rounded":"","clear-icon":"mdi-close","data-cy":"articles-search-input"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])},'v-data-table',{ search: _vm.search },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }