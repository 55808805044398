import { Mark } from "tiptap";
import { removeMark, updateMark } from "tiptap-commands";

interface CustomTextColorAttributes {
  color: string;
}

export default class CustomTextColor extends Mark {
  get name() {
    return "customtextcolor";
  }

  get defaultOptions() {
    return ["primary", "accent", "secondary"];
  }

  get schema() {
    return {
      attrs: {
        color: {
          default: "primary"
        }
      },
      defining: true,
      content: "inline*",
      parseDOM: ["primary", "accent", "secondary"].map(color => ({
        tag: `span.${color}--text`,
        attrs: { color }
      })),
      toDOM: ({ attrs }: { attrs: CustomTextColorAttributes }) => {
        return ["span", { class: `${attrs.color}--text` }, 0];
      }
    };
  }
  commands({ type }: { type: { instance: { attrs: CustomTextColorAttributes } } }) {
    return (attrs: CustomTextColorAttributes) => {
      if (this.editor.activeMarkAttrs.customtextcolor.color !== attrs.color) {
        return updateMark(type, attrs);
      }
      return removeMark(type);
    };
  }
}
