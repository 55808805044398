import { LoginDetails, LoginResponse, RefreshResponse } from "victoria-shared";
import request, { uninterceptedRequest } from "../request";

const CONTROLLER = "accounts";

export default {
  async login(loginDetails: LoginDetails): Promise<LoginResponse> {
    const req = await request.post(`${CONTROLLER}/authenticate`, loginDetails);
    return req.data;
  },
  async refreshToken(): Promise<RefreshResponse | undefined> {
    const req = await uninterceptedRequest.post(`${CONTROLLER}/refresh-token`);
    return req.status === 200 ? req.data : undefined;
  },
  async logout(): Promise<boolean> {
    const req = await request.post(`${CONTROLLER}/revoke-token`, { validateStatus: () => true });
    return req.status === 200;
  }
};
