










































import Vue, { toRefs, PropType } from "vue";
import { LinkAttrs } from "@/types";
export default Vue.extend({
  name: "LinkDialog",
  setup(props, context) {
    const rules = { required: (value: string) => !!value || "Required." };
    const { dialog, command, link } = toRefs(props);
    const close = () => {
      context.emit("update:dialog", false);
    };
    const save = () => {
      if (!link.value) return;
      command.value({ href: link.value });
      close();
    };
    const onChange = (val: string) => {
      context.emit("update:link", val);
    };
    return { rules, dialog, link, save, onChange, close };
  },
  props: {
    dialog: {
      type: Boolean
    },
    command: Function as PropType<(arg: LinkAttrs) => void>,
    link: String
  }
});
