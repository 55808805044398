




































import Vue, { PropType, ref, toRefs } from "vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "InformationRemoveButton",
  props: {
    refreshArticles: Function as PropType<() => Promise<void>>,
    id: String
  },
  setup(props, context) {
    const { refreshArticles, id } = toRefs(props);
    const dialog = ref(false);
    const loading = ref(false);
    const close = (): void => {
      dialog.value = false;
    };
    const remove = async () => {
      loading.value = true;
      try {
        await api.DeleteInformationArticle(id.value);
        alertService.actions.add({
          text: "Removed article.",
          type: AlertType.SUCCESS
        });
        await refreshArticles.value();
        context.emit("delete");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed removing article.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
      close();
    };
    return { refreshArticles, dialog, loading, close, remove };
  }
});
