













































































import Vue, { PropType, Ref, ref, toRefs } from "vue";
import api from "@/api";
import { toBase64Url } from "@/utils";
import { AlertType } from "@/services/alert/types";
import { useStore } from "@/store";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
const DEFAULT_ACCESS = 2;
import { FileAttrs } from "@/types";
export default Vue.extend({
  name: "ImageUploadDialog",
  props: {
    dialog: {
      type: Boolean
    },
    command: Function as PropType<(arg: FileAttrs) => void>
  },
  setup(props, context) {
    const { dialog, command } = toRefs(props);
    const file: Ref<string | undefined> = ref(undefined);
    let fileName: string | undefined = undefined;
    const loading = ref(false);
    const name: Ref<string | undefined> = ref(undefined);
    const isPdf = ref(false);
    const rules = { required: (value: string) => !!value || "Required." };
    const fileInput: Ref<HTMLFormElement | undefined> = ref(undefined);
    const store = useStore();
    const addAttachment = (attachmentId: string) =>
      store.dispatch("attachment/setAttachment", attachmentId);
    const close = (): void => {
      context.emit("update:dialog", false);
    };
    const openFileBrowser = (): void => {
      const file = fileInput.value;
      file?.$refs.input?.click();
    };
    const onChange = async (document: File): Promise<void> => {
      if (!document) return;
      isPdf.value = document.type === "application/pdf";
      loading.value = true;
      file.value = await toBase64Url(document);
      fileName = document.name;
      name.value = document.name?.replace(/\(\d+\)|\.pdf$/g, "").trim();
      loading.value = false;
    };
    const upload = async (): Promise<void> => {
      if (!file.value) return;
      loading.value = true;
      // Strip url part of the string
      const base64 = file.value.substring(file.value.indexOf(",") + 1);
      try {
        const { content } = await api.CompressImage({
          name: fileName || "unknown",
          content: base64
        });

        let attachment;

        if (isPdf.value) {
          attachment = await api.CreateAttachment({
            content: content,
            name: fileName || "unknown",
            access: DEFAULT_ACCESS
          });
          attachment.id && addAttachment(attachment.id);
        }
        command.value({
          src: isPdf.value ? attachment?.id : content,
          isPdf: isPdf.value,
          fileName: name.value
        });
        file.value = undefined;
        name.value = undefined;
        isPdf.value = false;
        fileInput.value?.reset();
        close();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed uploading image.";
        await alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    return {
      dialog,
      file,
      fileName,
      loading,
      name,
      isPdf,
      rules,
      fileInput,
      close,
      openFileBrowser,
      onChange,
      upload
    };
  }
});
