






import Vue, { toRefs, computed, PropType } from "vue";
import { Project } from "victoria-shared";
enum ProjectStatus {
  FUTURE = "Future",
  ACTIVE = "Active",
  PAST = "Past"
}
export default Vue.extend({
  name: "ProjectStatusChip",
  props: {
    project: Object as PropType<Project>,
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { project, small } = toRefs(props);
    const status = computed(() => {
      const projects = project.value;
      const currentDate = new Date();
      const completion = new Date(projects.completion);
      const commencement = new Date(projects.commencement);
      if (completion < currentDate) {
        return ProjectStatus.PAST;
      }
      if (commencement > currentDate) {
        return ProjectStatus.FUTURE;
      }
      return ProjectStatus.ACTIVE;
    });
    const color = computed(() => {
      switch (status.value) {
        case ProjectStatus.ACTIVE:
          return "success";
        case ProjectStatus.PAST:
          return "warning";
        case ProjectStatus.FUTURE:
          return "info";
        default:
          return "dark";
      }
    });
    return { small, color, status };
  }
});
