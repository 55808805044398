












































































































































































import Vue, { PropType, Ref, computed, ref, toRefs, watch } from "vue";
import api from "@/api";
import { User } from "victoria-shared";
import UserStatusChip from "@/components/UserStatusChip.vue";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "UserActionDialog",
  components: { UserStatusChip },
  props: {
    dialog: {
      type: Boolean
    },
    user: Object as PropType<User>,
    refreshUsers: Function as PropType<() => Promise<void>>
  },
  setup(props, context) {
    const confirmDeleteDialog = ref(false);
    const loading = ref(false);
    const updatedUser: Ref<User | undefined> = ref(undefined);
    const enableAccessFrom = ref(false);
    const enableAccessTo = ref(false);
    const { dialog, user, refreshUsers } = toRefs(props);
    const getPlaceholderDate = (additionalDays = 0) => {
      const date = new Date();
      date.setDate(date.getDate() + additionalDays);
      return date.toISOString().split("T")[0];
    };
    watch(user, (newVal: User) => {
      enableAccessFrom.value = !!newVal.activeFrom;
      enableAccessTo.value = !!newVal.activeTo;
      updatedUser.value = {
        activeFrom: getPlaceholderDate(),
        activeTo: getPlaceholderDate(1),
        ...newVal
      };
    });
    const isVerified = computed(() => {
      return user.value.isVerified;
    });

    const close = () => {
      context.emit("update:dialog", false);
    };
    const update = async (verified?: boolean) => {
      loading.value = true;
      try {
        updatedUser.value &&
          (await api.UpdateAccount({
            ...updatedUser.value,
            ...(!enableAccessFrom.value && { activeFrom: undefined }),
            ...(!enableAccessTo.value && { activeTo: undefined }),
            verified
          }));
        alertService.actions.add({ text: "Updated user successfully.", type: AlertType.SUCCESS });
        await refreshUsers.value();
        close();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed updating user.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    const remove = async () => {
      confirmDeleteDialog.value = false;
      loading.value = true;
      try {
        updatedUser.value && (await api.DeleteAccount(updatedUser.value.id));
        alertService.actions.add({ text: "Deleted user successfully.", type: AlertType.SUCCESS });
        await refreshUsers.value();
        close();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed deleting user.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    const acceptAndUpdate = () => {
      update(true);
    };

    return {
      confirmDeleteDialog,
      loading,
      updatedUser,
      enableAccessFrom,
      enableAccessTo,
      dialog,
      user,
      refreshUsers,
      isVerified,
      acceptAndUpdate,
      update,
      remove,
      close
    };
  }
});
