


























































































































import Vue, { ref, toRefs, PropType, Ref } from "vue";
import { VuetifyForm } from "@/types";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import { Project } from "victoria-shared";
import ProjectStatusChip from "@/components/ProjectStatusChip.vue";
import ProjectRemoveButton from "@/components/ProjectRemoveButton.vue";
import UserAccessForm from "@/components/UserAccessForm.vue";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
enum Method {
  UPDATE = "update",
  CREATE = "create"
}
const DEFAULT_ACCESS = 2;
export default Vue.extend({
  name: "ProjectActionButton",
  components: { ProjectStatusChip, ProjectRemoveButton, UserAccessForm },
  props: {
    refreshProjects: Function as PropType<() => Promise<void>>,
    project: {
      type: Object as PropType<Project>,
      default: Object
    },
    method: {
      type: String,
      default: Method.UPDATE,
      validator: value => [Method.UPDATE, Method.CREATE].includes(value as Method)
    }
  },
  setup(props) {
    const { refreshProjects, project, method } = toRefs(props);
    const dialog = ref(false);
    const loading = ref(false);
    const updatedProject: Ref<Project | undefined> = ref(undefined);
    const rules = { required: (value: string) => !!value || "Required." };
    const form: Ref<VuetifyForm | undefined> = ref(undefined);
    const reset = () => {
      updatedProject.value = {
        name: "",
        client: "",
        commencement: "",
        completion: "",
        summary: "",
        access: DEFAULT_ACCESS
      };
    };
    const close = (): void => {
      method.value === Method.CREATE && reset();
      dialog.value = false;
    };
    updatedProject.value = {
      access: DEFAULT_ACCESS,
      ...project.value
    } as Project;
    const getForm = (): VuetifyForm | undefined => {
      return form.value;
    };
    const validate = (): boolean | undefined => {
      const form = getForm();
      return form?.validate();
    };
    const submit = async (): Promise<void> => {
      if (validate()) {
        loading.value = true;
        try {
          const apiMethod = method.value === Method.UPDATE ? api.UpdateProject : api.CreateProject;
          updatedProject.value && (await apiMethod({ ...updatedProject.value }));
          alertService.actions.add({
            text: "Updated project successfully.",
            type: AlertType.SUCCESS
          });
          await refreshProjects.value();
          close();
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message =
            errorResponse?.response?.data?.message ||
            `Failed ${method.value === Method.UPDATE ? "updating" : "creating"} project.`;
          alertService.actions.add({ text: message, type: AlertType.ERROR });
        }
        loading.value = false;
      }
    };
    return {
      dialog,
      loading,
      updatedProject,
      rules,
      form,
      refreshProjects,
      project,
      method,
      Method,
      close,
      getForm,
      reset,
      validate,
      submit
    };
  }
});
