


































































import { AlertType } from "@/services/alert/types";
import { VuetifyForm } from "@/types";
import { VALIDATION } from "@/utils";
import Vue, { Ref, getCurrentInstance, ref } from "vue";
import authService from "@/services/auth";
import alertService from "@/services/alert";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "SignIn",
  setup() {
    const loading = ref(false);
    const valid = ref(false);
    const email = ref<string | undefined>(undefined);
    const password: Ref<string | undefined> = ref(undefined);
    const showPassword = ref(false);
    const rules = {
      ...VALIDATION.required(),
      ...VALIDATION.email()
    };
    const formInput = ref(undefined as VuetifyForm | undefined);
    const instance = getCurrentInstance();
    const router = instance?.proxy.$router;
    const form = (): VuetifyForm | undefined => {
      return formInput.value;
    };
    const validate = (): boolean | undefined => {
      return form()?.validate();
    };
    const submit = async (): Promise<void> => {
      loading.value = true;
      if (validate()) {
        try {
          email.value &&
            password.value &&
            (await authService.actions.login({
              email: email?.value,
              password: password.value
            }));
          router?.replace("/");
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message = errorResponse?.response?.data?.message || "Failed logging in";
          alertService.actions.add({ text: message, type: AlertType.ERROR });
        }
      }
      loading.value = false;
    };
    return {
      loading,
      valid,
      email,
      password,
      showPassword,
      rules,
      formInput,
      form,
      validate,
      submit
    };
  }
});
