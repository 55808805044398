var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","rounded":"lg","loading":_vm.loading ? 'accent' : false}},[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('div',[_c('v-card-title',[_vm._v("Invites")]),_c('v-card-subtitle',[_vm._v("List of invited users")])],1),_c('div',[_c('v-card-text',[_c('v-btn',{attrs:{"large":"","rounded":"","text":"","data-cy":"refresh-invites-button"},on:{"click":_vm.refresh}},[_vm._v(" Refresh "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invites,"items-per-page":10,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"data-cy":"invites-table"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.created))+" ")]}},{key:"item.expiry",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.expires))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('invite-status-chip',{attrs:{"invite":item,"small":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"error","loading":_vm.loadingItems.includes(item.id)},on:{"click":function($event){return _vm.openConfirmation(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c('v-btn',{attrs:{"small":"","color":"secondary","loading":_vm.loadingItems.includes(item.id)},on:{"click":function($event){return _vm.resend(item.id)}}},[_c('span',[_vm._v("Resend")]),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-send")])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","color":"primary","solo":"","hide-details":"","filled":"","flat":"","prepend-inner-icon":"mdi-magnify","label":"Search","rounded":"","clear-icon":"mdi-close","data-cy":"invites-search-input"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showConfirmation),callback:function ($$v) {_vm.showConfirmation=$$v},expression:"showConfirmation"}},[(_vm.showConfirmation)?_c('v-card',[_c('v-card-title',{staticClass:"headline text"},[_vm._v(" Confirm removal of invite ")]),_c('v-card-text',[_vm._v(" Are you sure you want to remove the invite of user "),_c('strong',[_vm._v(_vm._s(_vm.selected.email))]),_vm._v(" ? The invite sent to the user will no longer be valid to register with. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.showConfirmation = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.loadingItems.includes(_vm.selected.id)},on:{"click":function($event){return _vm.remove(_vm.selected.id)}}},[_vm._v(" Yes Remove ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }