var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"rounded":"lg","flat":"","loading":_vm.loading ? 'accent' : false}},[_c('v-layout',{attrs:{"justify-space-between":"","align-center":""}},[_c('div',[_c('v-card-title',[_vm._v("Users")]),_c('v-card-subtitle',[_vm._v("List of all users")])],1),_c('div',[_c('v-card-text',[_c('v-btn',{attrs:{"large":"","rounded":"","text":"","data-cy":"refresh-accounts-button"},on:{"click":_vm.refreshUsers}},[_vm._v(" Refresh "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-refresh")])],1)],1)],1)]),_c('user-action-dialog',{attrs:{"user":_vm.selected,"dialog":_vm.dialog,"refreshUsers":_vm.refreshUsers},on:{"update:dialog":function($event){_vm.dialog=$event}}}),_c('v-data-table',_vm._b({attrs:{"items":_vm.users,"items-per-page":10,"data-cy":"accounts-table"},on:{"click:row":function (item) { return _vm.selectUser(item); }},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('user-status-chip',{attrs:{"status":item.status,"small":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectUser(item)}}},[_c('span',[_vm._v("View more")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","color":"primary","solo":"","hide-details":"","filled":"","flat":"","prepend-inner-icon":"mdi-magnify","label":"Search","rounded":"","data-cy":"accounts-search-input"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-layout',{staticClass:"d-none d-sm-flex",attrs:{"justify-end":""}},_vm._l((_vm.presets),function(ref){
var filter = ref.filter;
var text = ref.text;
return _c('v-chip',{key:filter,staticClass:"mr-2 font-weight-medium overflow-visible",attrs:{"link":"","color":_vm.isActiveFilter(filter) ? 'secondary' : '',"data-cy":("filter-by-" + (filter.toLowerCase()) + "-button")},on:{"click":function($event){return _vm.setFilter(filter)}}},[(filter === _vm.UserStatus.REQUESTED)?_c('v-badge',{attrs:{"content":_vm.noOfRequests,"value":_vm.noOfRequests,"color":"accent","bordered":""}},[(_vm.isActiveFilter(filter))?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e(),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(text))])],1):[(_vm.isActiveFilter(filter))?_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-checkbox-marked-circle-outline ")]):_vm._e(),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(text))])]],2)}),1)],1)]},proxy:true}])},'v-data-table',{ headers: _vm.headers, search: _vm.search },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }