import { Article } from "victoria-shared";
import request from "../request";

const CONTROLLER = "articles";

export default {
  async GetArticles(): Promise<Array<Article>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetArticle(id: string): Promise<Article> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateArticle(article: Article): Promise<Article> {
    const req = await request.post(`${CONTROLLER}`, article);
    return req.data;
  },
  async UpdateArticle(article: Article): Promise<Article> {
    const req = await request.put(`${CONTROLLER}/${article.id}`, article);
    return req.data;
  },
  async DeleteArticle(id: string): Promise<void> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  }
};
