















import Vue, { Ref, onBeforeUnmount, ref, toRefs } from "vue";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Heading,
  Bold,
  Italic,
  Underline,
  ListItem,
  OrderedList,
  BulletList,
  Blockquote,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Placeholder
} from "tiptap-extensions";
import CustomLink from "@/lib/tiptap/CustomLink";
import CustomTextColor from "@/lib/tiptap/CustomTextColor";
import EditorMenu from "@/components/EditorMenu.vue";
import PdfNode from "@/lib/tiptap/CustomImage";
export default Vue.extend({
  name: "ArticleEditor",
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenu
  },
  props: {
    content: {
      type: String,
      default: undefined
    }
  },
  setup(props, context) {
    const { content } = toRefs(props);
    const editor: Ref<Editor | undefined> = ref(undefined);
    editor.value = new Editor({
      content: content.value,
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Blockquote(),
        new Image(),
        new PdfNode(),
        new CustomLink(),
        new Table({
          resizable: true
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Placeholder({
          emptyNodeText: "Write article content...",
          emptyEditorClass: "is-editor-empty"
        }),
        new CustomTextColor()
      ],
      onUpdate: ({ getHTML }) => {
        context.emit("update:content", getHTML());
      }
    });

    onBeforeUnmount(() => {
      editor.value && editor.value.destroy();
    });
    return { content, editor };
  }
});
