






































































































import Vue, { PropType, Ref, computed, ref, toRefs } from "vue";
import api from "@/api";
import { Chapter } from "@/types";
import { ReorderDirection } from "@/enums";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "HandbookMenu",
  props: {
    id: String,
    chapters: Array as PropType<Chapter[]>,
    updating: {
      type: Boolean,
      default: false
    },
    hasPendingChanges: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { id, chapters, updating, hasPendingChanges } = toRefs(props);
    const selectedItem = ref(-1);
    const pendingNavigation: Ref<Chapter | undefined> = ref(undefined);
    const loading = ref(false);
    const loadingReordering = ref(false);
    const menu = computed(() => {
      return chapters.value;
    });
    const selectChapter = (chapter: Chapter | undefined, forceChange = false): void => {
      if (hasPendingChanges.value && !forceChange) {
        pendingNavigation.value = chapter;
      } else {
        pendingNavigation.value = undefined;
        context.emit("selectChapter", chapter);
      }
    };
    const cancelNavigation = (): void => {
      pendingNavigation.value = undefined;
    };
    const reorder = async (chapterId: string, direction: ReorderDirection): Promise<void> => {
      if (id.value) {
        return alertService.actions.add({
          text: "Can't reorder while a chapter is open.",
          type: AlertType.ERROR
        });
      }
      loadingReordering.value = true;
      try {
        await api.ReorderChapter(chapterId, { direction });
        context.emit("update");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed reordering chapter.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loadingReordering.value = false;
    };
    const createChapter = async (): Promise<void> => {
      loading.value = true;
      try {
        await api.CreateChapter({ title: "New chapter..." });
        context.emit("update");
        alertService.actions.add({
          text: "Added new chapter successfully.",
          type: AlertType.SUCCESS
        });
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed creating chapter.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    return {
      id,
      chapters,
      loadingReordering,
      hasPendingChanges,
      pendingNavigation,
      loading,
      updating,
      selectedItem,
      menu,
      selectChapter,
      cancelNavigation,
      reorder,
      createChapter,
      ReorderDirection
    };
  }
});
