






























import { computed, ref } from "vue";
import alertService from "@/services/alert";
import Vue from "vue";
export default Vue.extend({
  name: "alerts",
  setup() {
    const defaultActionText = ref("Retry");
    const alerts = computed(() => {
      return alertService.state.alerts;
    });
    const close = (id: number): void => {
      return alertService.actions.remove(id);
    };
    const performAction = (id: number, action: Function | undefined): void => {
      action && action();
      close(id);
    };
    return { defaultActionText, alerts, close, performAction };
  }
});
