





















































































import Vue, { ref, watch, computed, toRefs, PropType, Ref } from "vue";
import { VuetifyForm } from "@/types";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import { Article } from "victoria-shared";
import ArticleRemoveButton from "@/components/ArticleRemoveButton.vue";
import ArticleImageInput from "@/components/ArticleImageInput.vue";
import ArticleEditor from "@/components/ArticleEditor.vue";
import UserAccessForm from "@/components/UserAccessForm.vue";
import alertService from "@/services/alert";
import { useStore } from "@/store/index";
import { AxiosError } from "axios";
enum Method {
  UPDATE = "update",
  CREATE = "create"
}
const DEFAULT_ACCESS = 2;
export default Vue.extend({
  name: "ArticleActionButton",
  components: { ArticleRemoveButton, ArticleImageInput, ArticleEditor, UserAccessForm },
  props: {
    refreshArticles: Function as PropType<() => Promise<void>>,
    article: {
      type: Object as PropType<Article>,
      default: Object
    },
    method: {
      type: String,
      default: Method.UPDATE,
      validator: value => [Method.UPDATE, Method.CREATE].includes(value as Method)
    }
  },
  setup(props) {
    const store = useStore();
    const resetAttachment = () => store.dispatch("attachment/reset");
    const attachments = computed(() => store.getters["attachment/attachments"]);
    const { refreshArticles, article, method } = toRefs(props);
    const dialog = ref(false);
    const loading = ref(false);
    const updatedArticle: Ref<Article | undefined> = ref(undefined);
    const rules = { required: (value: string) => !!value || "Required." };
    const id = ref(1);
    const form: Ref<VuetifyForm | undefined> = ref(undefined);
    const setForm = (): void => {
      updatedArticle.value = {
        access: DEFAULT_ACCESS,
        ...article.value
      };
    };

    const getForm = (): VuetifyForm | undefined => {
      return form.value;
    };
    const validate = (): boolean | undefined => {
      if (!updatedArticle.value?.content) {
        alertService.actions.add({
          text: "Article content missing",
          type: AlertType.ERROR
        });
        return false;
      }
      const form = getForm();
      return form?.validate();
    };
    const reset = (): void => {
      updatedArticle.value = {
        title: "",
        image: "",
        content: "",
        access: DEFAULT_ACCESS
      };
    };
    const close = (): void => {
      method.value === Method.CREATE ? reset() : setForm();
      id.value += 1;
      dialog.value = false;
      if (attachments.value.length > 0) {
        api.DeleteAttachment(attachments.value);
        resetAttachment();
      }
    };
    const submit = async (): Promise<void> => {
      if (validate()) {
        loading.value = true;
        try {
          const apiMethod = method.value === Method.UPDATE ? api.UpdateArticle : api.CreateArticle;
          updatedArticle.value &&
            (await apiMethod({ ...updatedArticle.value, attachments: attachments.value }));
          alertService.actions.add({
            text: "Updated article successfully.",
            type: AlertType.SUCCESS
          });
          await resetAttachment();
          await refreshArticles.value();
          close();
        } catch (error) {
          const errorResponse = error as AxiosError;
          const message =
            errorResponse?.response?.data?.message ||
            `Failed ${method.value === Method.UPDATE ? "updating" : "creating"} article.`;
          alertService.actions.add({ text: message, type: AlertType.ERROR });
        }
        loading.value = false;
      }
    };
    watch(
      () => article,
      () => setForm(),
      { immediate: true }
    );
    setForm();
    return {
      refreshArticles,
      article,
      method,
      Method,
      dialog,
      loading,
      updatedArticle,
      rules,
      id,
      form,
      setForm,
      getForm,
      validate,
      reset,
      close,
      submit
    };
  }
});
