



























































































import Vue, { Ref, computed, ref } from "vue";
import { User } from "victoria-shared";
import { UserStatus } from "@/enums";
import UserActionDialog from "@/components/UserActionDialog.vue";
import UserStatusChip from "@/components/UserStatusChip.vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "UserTable",
  components: { UserActionDialog, UserStatusChip },
  setup() {
    const loading = ref(false);
    const dialog = ref(false);
    const selected: Ref<User | undefined> = ref(undefined);
    const search: Ref<string | undefined> = ref(undefined);
    const sortBy: Ref<string | undefined> = ref(undefined);
    const users: Ref<User[]> = ref([]);
    const presets = ref([
      { text: "All users", filter: "" },
      { text: "Access", filter: UserStatus.ACCESS },
      { text: "Requested", filter: UserStatus.REQUESTED },
      { text: "Expired", filter: UserStatus.EXPIRED }
    ]);
    const headers = [
      {
        text: "Firstname",
        value: "firstName"
      },
      {
        text: "Lastname",
        value: "lastName"
      },
      { text: "Company", value: "company" },
      { text: "Email", value: "email" },
      { text: "Type", value: "userType" },
      { text: "Role", value: "role" },
      {
        text: "Status",
        value: "status",
        align: "center",
        filter: (value: string) => {
          if (!sortBy.value) return true;
          return value === sortBy.value;
        }
      },
      { text: "Actions", value: "actions", sortable: false, align: "right" }
    ];

    const noOfRequests = computed(() => {
      return users.value.filter(({ status }) => status === UserStatus.REQUESTED).length;
    });
    const setFilter = (filter: string): void => {
      sortBy.value = filter;
    };
    const isActiveFilter = (filter: string): boolean => {
      return filter === sortBy.value;
    };
    const selectUser = (user: User): void => {
      selected.value = user;
      dialog.value = true;
    };
    const refreshUsers = async (): Promise<void> => {
      loading.value = true;
      try {
        users.value = await api.GetAccounts();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed fetching users.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
    };
    refreshUsers();
    return {
      loading,
      dialog,
      selected,
      search,
      users,
      presets,
      UserStatus,
      headers,
      noOfRequests,
      selectUser,
      refreshUsers,
      isActiveFilter,
      setFilter
    };
  }
});
