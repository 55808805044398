






























import Vue, { toRefs } from "vue";
import { UserType, UserTypeValue } from "@/enums";

export default Vue.extend({
  name: "UserAccessForm",
  props: {
    access: {
      type: Number,
      default: 0
    }
  },
  setup(props, context) {
    const { access } = toRefs(props);
    const updateAccess = (_checked: number | undefined, type: UserType) => {
      context.emit("update:access", access.value ^ UserTypeValue[type]);
    };
    const isActive = (type: UserType) => {
      return access.value & UserTypeValue[type];
    };
    return {
      UserTypeValue,
      UserType,
      updateAccess,
      isActive
    };
  }
});
