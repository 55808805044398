import attachment from "./modules/attachment";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    attachment
  }
});

export default store;
export const useStore = () => store;
