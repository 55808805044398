import Vue from "vue";
import { JWT, LoginDetails, User } from "victoria-shared";
import api from "@/api";
import router from "@/router";
import { AuthState } from "./types";

function getUserFromLocalStorage(): User | undefined {
  try {
    const userString = localStorage.getItem("user");
    return userString && JSON.parse(userString);
  } catch {
    return undefined;
  }
}

function parseToken(token: string): JWT | undefined {
  try {
    return JSON.parse(atob(token.split(".")[1])) as JWT;
  } catch {
    return undefined;
  }
}

const initialState = (): AuthState => ({
  user: getUserFromLocalStorage() || undefined,
  token: localStorage.getItem("token") || ""
});

const state = Vue.observable(initialState());

const authStore = {
  state,
  actions: {
    async login(loginDetails: LoginDetails) {
      const {
        id,
        firstName,
        lastName,
        company,
        email,
        phoneNumber,
        isVerified,
        status,
        jwtToken
      } = await api.login(loginDetails);
      this.setUser({ id, firstName, lastName, company, email, phoneNumber, isVerified, status });
      this.setToken(jwtToken);
    },
    async refreshToken() {
      try {
        const result = await api.refreshToken();
        if (result) {
          this.setToken(result.jwtToken);
        }
      } catch (error) {
        console.error("Refresh token error:", error);
      }
    },
    async logout() {
      try {
        await api.logout();
      } finally {
        this.unsetUser();
        this.unsetToken();
        router.push({ name: "Login" });
      }
    },
    setUser(user: User | undefined) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    setToken(token: string) {
      localStorage.setItem("token", token);
      state.token = token;
    },
    unsetUser() {
      localStorage.removeItem("user");
      state.user = undefined;
    },
    unsetToken() {
      localStorage.removeItem("token");
      state.token = "";
    }
  },
  get tokenExpiry() {
    try {
      const exp = parseToken(state.token)?.exp;
      // Convert to milliseconds
      return exp ? exp * 1000 : undefined;
    } catch {
      return undefined;
    }
  },
  get isAuthenticated() {
    return !!(state.user && state.token);
  }
};

export default authStore;
