import Vue from "vue";
import { AlertType, AlertConfig, AlertState } from "./types";

const DISMISS_INTERVAL = 5000;

const DEFAULT_TYPE = AlertType.INFO;

let currentToastId = 0;

const initialState = (): AlertState => ({ alerts: [] });

const state = Vue.observable(initialState());

const alertStore = {
  state,
  actions: {
    add({
      text,
      action,
      actionText,
      type = DEFAULT_TYPE,
      dismissible = true,
      dismissAfter = DISMISS_INTERVAL
    }: AlertConfig) {
      const id = ++currentToastId;
      state.alerts.push({ id, text, type, dismissAfter, action, actionText, dismissible });
      setTimeout(() => (state.alerts = state.alerts.filter(a => a.id !== id)), dismissAfter);
    },
    addPersistent({
      text,
      action,
      actionText,
      identifier,
      type = DEFAULT_TYPE,
      dismissible = false
    }: AlertConfig) {
      const id = ++currentToastId;
      if (state.alerts.every(a => a.identifier !== identifier)) {
        state.alerts.push({ id, text, type, action, actionText, identifier, dismissible });
      }
    },
    remove(id: number) {
      state.alerts = state.alerts.filter(a => a.id !== id);
    },
    removePersistent(identifier: string) {
      state.alerts = state.alerts.filter(a => a.identifier !== identifier);
    }
  }
};

export default alertStore;
