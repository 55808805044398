import { InviteRequest } from "@/types";
import { Invite } from "victoria-shared";
import request from "../request";

const CONTROLLER = "invites";

export default {
  async GetInvites(): Promise<Array<Invite>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetInvite(id: string): Promise<Invite> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateInvite(invite: InviteRequest): Promise<Invite> {
    const req = await request.post(`${CONTROLLER}`, { ...invite });
    return req.data;
  },
  async DeleteInvite(id: string): Promise<void> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async ResendInvite(id: string): Promise<Invite> {
    const req = await request.post(`${CONTROLLER}/${id}/resend`);
    return req.data;
  }
};
