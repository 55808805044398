






import Vue, { PropType, computed, toRefs } from "vue";
import { UserStatus } from "@/enums";
import { userStatusToColor } from "@/utils";
export default Vue.extend({
  name: "UserStatusChip",
  props: {
    status: String as PropType<UserStatus>,
    small: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { status, small } = toRefs(props);
    const color = computed(() => {
      return userStatusToColor(status.value);
    });
    return { color, small };
  }
});
