import chapters from "./controllers/chapters";
import accounts from "./controllers/accounts";
import authentication from "./controllers/authentication";
import invites from "./controllers/invites";
import images from "./controllers/images";
import projects from "./controllers/projects";
import articles from "./controllers/articles";
import informationArticles from "./controllers/informationArticles";
import attachments from "./controllers/attachments";

export default {
  ...chapters,
  ...authentication,
  ...accounts,
  ...invites,
  ...images,
  ...projects,
  ...articles,
  ...informationArticles,
  ...attachments
};
