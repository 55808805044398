import { Chapter, ChapterCreateRequest, ChapterReorderRequest } from "@/types";
import request from "../request";

const CONTROLLER = "chapters";

export default {
  async GetChapters(): Promise<Array<Chapter>> {
    const req = await request.get(`${CONTROLLER}`);
    return req.status === 200 ? req.data : [];
  },
  async GetChaptersCompact(): Promise<Array<Chapter>> {
    const req = await request.get(`${CONTROLLER}`, {
      params: { compact: true }
    });
    return req.status === 200 ? req.data : [];
  },
  async GetChapter(id: string): Promise<Chapter> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async CreateChapter(chapter: ChapterCreateRequest): Promise<Chapter> {
    const req = await request.post(`${CONTROLLER}`, { ...chapter });
    return req.data;
  },
  async DeleteChapter(id: string): Promise<Chapter> {
    const req = await request.delete(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async UpdateChapter(id: string, chapter: Chapter): Promise<boolean> {
    const req = await request.put(`${CONTROLLER}/${id}`, {
      ...chapter
    });
    return req.status === 204;
  },
  async ReorderChapter(id: string, reorder: ChapterReorderRequest): Promise<boolean> {
    const req = await request.post(`${CONTROLLER}/${id}/reorder`, reorder);
    return req.status === 200;
  }
};
