






























































import Vue, { PropType, Ref, computed, onBeforeUnmount, ref, toRefs, watch } from "vue";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Heading,
  Bold,
  Italic,
  Underline,
  ListItem,
  OrderedList,
  BulletList,
  Blockquote,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Placeholder
} from "tiptap-extensions";
import CustomLink from "@/lib/tiptap/CustomLink";
import CustomTextColor from "@/lib/tiptap/CustomTextColor";
import EditorMenu from "@/components/EditorMenu.vue";
import { Chapter } from "@/types";
import UserAccessForm from "@/components/UserAccessForm.vue";
import DeleteChapterButton from "@/components/DeleteChapterButton.vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "ChapterEditor",
  components: {
    EditorContent,
    EditorMenuBar,
    EditorMenu,
    UserAccessForm,
    DeleteChapterButton
  },
  props: {
    content: {
      type: String,
      default: undefined
    },
    heading: {
      type: String,
      default: undefined
    },
    access: {
      type: Number,
      default: undefined
    },
    chapter: {
      type: Object as PropType<Chapter>
    }
  },
  setup(props, context) {
    const { content, heading, access, chapter } = toRefs(props);
    const editor: Ref<Editor | undefined> = ref(undefined);
    const loading = ref(false);
    const localAccess = ref(access);
    const isChanged = computed<boolean>(() => {
      const section = chapter.value;
      return (
        access.value !== section.access ||
        heading.value !== section.title ||
        content.value !== section.content
      );
    });
    watch(localAccess, newVal => {
      context.emit("update:access", newVal);
    });
    const save = async (): Promise<void> => {
      const section = {
        ...chapter.value,
        title: heading.value,
        content: content.value,
        access: access.value
      };
      try {
        loading.value = true;
        await api.UpdateChapter(section.chapterId, section);
        alertService.actions.add({
          text: "Chapter was saved successfully.",
          type: AlertType.SUCCESS
        });
        context.emit("save", section);
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed saving chapter";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
        context.emit("update");
      } finally {
        loading.value = false;
      }
    };
    editor.value = new Editor({
      content: content.value,
      extensions: [
        new Bold(),
        new Italic(),
        new Underline(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new Blockquote(),
        new Image(),
        new CustomLink(),
        new Table({
          resizable: true
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new Placeholder({
          emptyNodeText: "Write chapter content...",
          emptyEditorClass: "is-editor-empty"
        }),
        new CustomTextColor()
      ],
      onUpdate: ({ getHTML }) => {
        context.emit("update:content", getHTML());
      }
    });
    onBeforeUnmount(() => {
      editor.value && editor.value.destroy();
    });
    return { content, heading, access, chapter, editor, loading, localAccess, isChanged, save };
  }
});
