







import Vue from "vue";
import authService from "@/services/auth";
import alertService from "@/services/alert";
export default Vue.extend({
  name: "SignOutButton",
  setup() {
    const signOut = async () => {
      await authService.actions.logout();
      await alertService.actions.add({ text: "Logged out successfully!" });
    };
    return {
      signOut
    };
  }
});
