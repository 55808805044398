export interface AlertState {
  alerts: Array<Alert>;
}

export interface AlertConfig {
  text: string;
  type?: AlertType;
  dismissAfter?: number;
  action?: Function;
  actionText?: string;
  identifier?: string;
  dismissible?: boolean;
}

export interface Alert {
  id: number;
  text: string;
  type: AlertType;
  dismissAfter?: number;
  action?: Function;
  actionText?: string;
  identifier?: string;
  dismissible?: boolean;
}

export enum AlertType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success"
}

export interface AlertService {
  state: AlertState;
  actions: {
    add(params: AlertConfig): void;
    addPersistent(params: AlertConfig): void;
    remove(id: number): void;
    removePersistent(identifier: string): void;
  };
}
