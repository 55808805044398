import { Attachment } from "victoria-shared";
import request from "../request";

const CONTROLLER = "attachment";

export default {
  async CreateAttachment(attachment: Attachment): Promise<Attachment> {
    const req = await request.post(`${CONTROLLER}`, attachment);
    return req.data;
  },
  async GetAttachment(id: string): Promise<Attachment> {
    const req = await request.get(`${CONTROLLER}/${id}`);
    return req.data;
  },
  async DeleteAttachment(attachment: Array<string>): Promise<Attachment> {
    const config = {
      data: {
        attachments: attachment
      }
    };
    const req = await request.delete(`${CONTROLLER}`, config);
    return req.data;
  }
};
