import { UserStatus } from "./enums";
import { Chapter } from "./types";

const sortChapters = (a: Chapter, b: Chapter) => a.displayOrder - b.displayOrder;

export function userStatusToColor(status: UserStatus) {
  switch (status) {
    case UserStatus.ACCESS:
      return "success";
    case UserStatus.EXPIRED:
      return "error";
    case UserStatus.REQUESTED:
      return "warning";
    default:
      return "dark";
  }
}

export function getChapterStructure(chapters: Chapter[]) {
  const rootChapters = chapters.filter(({ parentId }) => !parentId).sort(sortChapters);

  const structure = rootChapters.map((chapter: Chapter) => ({
    ...chapter,
    subChapters: chapters
      .filter(({ parentId }) => parentId === chapter.chapterId)
      .sort(sortChapters)
  }));

  return structure;
}

export function toBase64Url(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

export function isEmail(email: string) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email?.trim());
}

export const VALIDATION = {
  email: () => ({ email: (value: string) => isEmail(value) || "Invalid e-mail." }),
  required: () => ({ required: (value: string) => !!value || "Required." }),
  min: (chars: number) => ({
    min: (value: string) => (value && value.length >= chars) || `Min ${chars} characters.`
  })
};
