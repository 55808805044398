











import Vue, { Ref, ref } from "vue";
import { Invite } from "victoria-shared";
import UserTable from "@/components/UserTable.vue";
import InviteTable from "@/components/InviteTable.vue";
import InviteUserButton from "@/components/InviteUserButton.vue";
import { AlertType } from "@/services/alert/types";
import api from "@/api";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "Users",
  components: { UserTable, InviteTable, InviteUserButton },
  setup() {
    const invites: Ref<Invite[]> = ref([]);
    const refreshInvites = async (): Promise<void> => {
      try {
        invites.value = await api.GetInvites();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed fetching invites.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
    };
    return { invites, refreshInvites };
  }
});
