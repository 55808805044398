
























import Vue, { Ref, ref } from "vue";
import api from "@/api";
import { Project } from "victoria-shared";
import { AlertType } from "@/services/alert/types";
import ProjectTable from "@/components/ProjectTable.vue";
import ProjectActionButton from "@/components/ProjectActionButton.vue";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "Projects",
  components: { ProjectTable, ProjectActionButton },
  setup() {
    const projects: Ref<Project[]> = ref([]);
    const refreshProjects = async (): Promise<void> => {
      try {
        projects.value = await api.GetProjects();
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed fetching projects.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
    };
    return { projects, refreshProjects };
  }
});
