




































import Vue, { toRefs, ref, PropType } from "vue";
import api from "@/api";
import { AlertType } from "@/services/alert/types";
import alertService from "@/services/alert";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "ProjectRemoveButton",
  props: {
    refreshProjects: Function as PropType<() => Promise<void>>,
    id: String
  },
  setup(props, context) {
    const { refreshProjects, id } = toRefs(props);
    const dialog = ref(false);
    const loading = ref(false);
    const close = (): void => {
      dialog.value = false;
    };
    const remove = async () => {
      loading.value = true;
      try {
        await api.DeleteProject(id.value);
        alertService.actions.add({
          text: "Removed project.",
          type: AlertType.SUCCESS
        });
        await refreshProjects.value();
        context.emit("delete");
      } catch (error) {
        const errorResponse = error as AxiosError;
        const message = errorResponse?.response?.data?.message || "Failed removing project.";
        alertService.actions.add({ text: message, type: AlertType.ERROR });
      }
      loading.value = false;
      close();
    };
    return { remove, dialog, loading, close };
  }
});
